import React from "react";

const ServicesBox = ({ service }: any) => {
  return (
    <React.Fragment>
      <div className="service-box">
        <div className="service-icon text-primary">
          <i className={service.icon}></i>
        </div>
        <h4 className="text-uppercase text-dark fw-medium fs-16 mt-4">
          {service.title}
        </h4>

        <p className="text-muted fs-16">{service.desc}</p>
      </div>
    </React.Fragment>
  );
};

export default ServicesBox;
