import React from "react";
import { Col, Container, Row } from "reactstrap";
import Clients from "../About/Clients";
import Pagination from "../Products/Pagination";
import List from "./List";
import RightSidebar from "./RightSidebar";

const BlogList = () => {
  return (
    <React.Fragment>
      <section>
        <Container>
          <Row>
            <Col lg={9}>
              <h2 className="text-dark fw-light lh-base mt-5">
                Blog, Stories &
                <span className="fw-medium title"> latest news.</span>
              </h2>
              <p className="fs-16 text-muted mb-0">
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                consequat ipsum, nec sagittis sem nibh id elit. Proin gravida
                nibh vel velit auctor Aenean sollicitudin, adipisicing elit sed
                lorem quis bibendum auctor.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sm-section">
        <Container>
          <Row>
            {/* import List  */}
            <List />

            {/* import RigthSidebar */}
            <RightSidebar />
            <Pagination />
          </Row>
        </Container>
      </section>

      <Clients sectionTitle={false} />
    </React.Fragment>
  );
};

export default BlogList;
