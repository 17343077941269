import { Icon } from "@ailibs/feather-react-ts";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const ReviewForm = () => {
  return (
    <React.Fragment>
      <div>
        <h6 className="lh-base fw-semibold mt-4 mb-3">Add Your Review</h6>
        <form method="post">
          <Row className="g-4 mb-2">
            <Col lg={6}>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your name"
                />
                <label className="text-muted">Your name</label>
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Your email"
                />
                <label className="text-muted">Email address</label>
              </div>
            </Col>
          </Row>
          <Row className="g-4 mb-2">
            <div className="col-lg-12">
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Your Message...."
                  style={{ height: "100px" }}
                ></textarea>
                <label className="text-muted">Your Review</label>
              </div>
            </div>
          </Row>
          <Row>
            <ul className="list-unstyled list-inline mb-0">
              <li className="fw-semibold text-muted list-inline-item lh-lg">
                Add Your Review :
              </li>
              <li className="list-inline-item me-0">
                <i className="mdi mdi-star fs-17 text-warning"></i>
              </li>
              <li className="list-inline-item me-0">
                <i className="mdi mdi-star fs-17 text-warning"></i>
              </li>
              <li className="list-inline-item me-0">
                <i className="mdi mdi-star fs-17 text-warning"></i>
              </li>
              <li className="list-inline-item me-0">
                <i className="mdi mdi-star fs-17 text-warning"></i>
              </li>
              <li className="list-inline-item me-0">
                <i className="mdi mdi-star-half-full fs-17 text-warning"></i>
              </li>
            </ul>
          </Row>
          <Row>
            <Col lg={12} className="mt-3">
              <Link to="#" className="btn btn-primary">
                Send Review <Icon name="arrow-right" className="icon-xs" />
              </Link>
            </Col>
          </Row>
        </form>
      </div>
    </React.Fragment>
  );
};

export default ReviewForm;
