import React from "react";
import routes from "./allRoutes";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Layout from "../Layout/index";



const Index = () => {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          {routes.map((route: any, idx: number) => (

            <Route
              path={route.path}
              element={
                <Layout>
                  {route.component}
                </Layout>}
              key={idx}
            />
          ))}
        </Routes>
      </Router>
    </React.Fragment>
  );
};

export default Index;
