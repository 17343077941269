import React from "react";
import { Col, Container, Row } from "reactstrap";
import RightSidebar from "../BlogList/RightSidebar";
import SingleDetailsPage from "./SingleDetailsPage";

const SinglePost = () => {
  return (
    <React.Fragment>
      <section>
        <Container>
          <Row>
            <Col lg={9}>
              <h2 className="text-dark fw-light lh-base mt-5">
                Unique & authentic vintage
                <span className="fw-medium title"> designer collection</span>
              </h2>
              <p className="fs-16 text-muted mb-0">
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                consequat ipsum, nec sagittis sem nibh id elit. Proin gravida
                nibh vel velit auctor Aenean sollicitudin, adipisicing elit sed
                lorem quis bibendum auctor.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sm-section">
        <Container>
          <Row>
            <Col lg={8}>
              <SingleDetailsPage />
            </Col>
            <RightSidebar />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default SinglePost;
