import React from "react";
// import withRouter from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import withRouter from "../Component/withRouter";

const Layout = (props: any) => {
  // const Component = props.children;
  return (
    <React.Fragment>
      {/* navbar */}
      <Navbar />

      {/* page content */}
      {/* <Component {...props.children} /> */}
      {props.children}

      {/* footer */}
      <Footer />
    </React.Fragment>
  );
};

export default withRouter(Layout);
