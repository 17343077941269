import React from "react";
import { Col, Container, Row } from "reactstrap";

//Import images
import brand1 from "../../assets/images/brand/brand-1.png";
import brand2 from "../../assets/images/brand/brand-2.png";
import brand3 from "../../assets/images/brand/brand-3.png";
import brand4 from "../../assets/images/brand/brand-4.png";
import brand5 from "../../assets/images/brand/brand-5.png";

const Clients = ({ sectionTitle }: any) => {
  return (
    <React.Fragment>
      <section
        className={
          sectionTitle
            ? "client-section section mt-3"
            : "client-section sm-section"
        }
      >
        <Container>
          {sectionTitle && (
            <Row>
              <Col xs={12} className="text-center mb-5">
                <h4 className="fw-semibold border-bottom d-inline-block text-dark pb-3">
                  Trusted by Thousands
                </h4>
              </Col>
            </Row>
          )}

          <Row className="justify-content-between">
            <Col xs={12} className="col-md-auto mt-3 py-lg-0 py-3">
              <div className="client-box">
                <img
                  src={brand1}
                  className="mx-auto d-block img-fluid"
                  alt="client-logo"
                />
              </div>
            </Col>
            <Col xs={12} className="col-md-auto mt-3 py-lg-0 py-3">
              <div className="client-box">
                <img
                  src={brand2}
                  className="mx-auto d-block img-fluid"
                  alt="client-logo"
                />
              </div>
            </Col>
            <Col xs={12} className="col-md-auto mt-3 py-lg-0 py-3">
              <div className="client-box">
                <img
                  src={brand3}
                  className="mx-auto d-block img-fluid"
                  alt="client-logo"
                />
              </div>
            </Col>
            <Col xs={12} className="col-md-auto mt-3 py-lg-0 py-3">
              <div className="client-box">
                <img
                  src={brand4}
                  className="mx-auto d-block img-fluid"
                  alt="client-logo"
                />
              </div>
            </Col>
            <Col xs={12} className="col-md-auto mt-3 py-lg-0 py-3">
              <div className="client-box">
                <img
                  src={brand5}
                  className="mx-auto d-block img-fluid"
                  alt="client-logo"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Clients;
