//import images
import product1 from "../../assets/images/products/pro-1.jpg";
import product2 from "../../assets/images/products/pro-2.jpg";
import product3 from "../../assets/images/products/pro-3.jpg";
import product4 from "../../assets/images/products/pro-4.jpg";
import product5 from "../../assets/images/products/pro-5.jpg";
import product6 from "../../assets/images/products/pro-6.jpg";
import product7 from "../../assets/images/products/pro-7.jpg";
import product8 from "../../assets/images/products/pro-8.jpg";
import product9 from "../../assets/images/products/pro-9.jpg";

interface ProductProps {
    id : number;
    img : any;
    discount ?: string;
    price : string;
    discountPrice : string;
    rating : string;
    desc : string;
}

const products : Array<ProductProps> = [
    {
        id : 1,
        img : product1,
        discount : "-20%",
        price : "$29.00",
        discountPrice : "$30.00",
        rating : "5.0",
        desc : "Maxbell USB Rechargeable Electronic Flameless"
    },
    {
        id : 2,
        img : product2,
        price : "$35.00",
        discountPrice : "$45.00",
        rating : "4.8",
        desc : "PRd PU Tan Casual regular Walletious"
    },
    {
        id : 3,
        img : product3,
        discount : "-18%",
        price : "$20.00",
        discountPrice : "$30.00",
        rating : "4.9",
        desc : "Children Decorated Shoes Cotton Green"
    },
    {
        id : 4,
        img : product4,
        price : "$39.00",
        discountPrice : "$59.00",
        rating : "5.0",
        desc : "Maxbell USB Rechargeable Electronic Flameless"
    },
    {
        id : 5,
        img : product5,
        price : "$35.00",
        discountPrice : "$45.00",
        rating : "4.8",
        desc : "PRd PU Tan Casual regular Walletious"
    },
    {
        id : 6,
        img : product6,
        price : "$20.00",
        discountPrice : "$30.00",
        rating : "4.9",
        desc : "Children Decorated Shoes Cotton Green"
    },
    {
        id : 7,
        img : product7,
        price : "$99.00",
        discountPrice : "$95.00",
        rating : "5.0",
        desc : "Maxbell USB Rechargeable Electronic Flameless"
    },
    {
        id : 8,
        img : product8,
        discount : "-26%",
        price : "$35.00",
        discountPrice : "$45.00",
        rating : "4.8",
        desc : "PRd PU Tan Casual regular Walletious"
    },
    {
        id : 9,
        img : product9,
        discount : "-15%",
        price : "$20.00",
        discountPrice : "$30.00",
        rating : "4.9",
        desc : "Children Decorated Shoes Cotton Green"
    },
]

export {products};