import React from "react";
import { Col, Container, Row } from "reactstrap";
import WorkPortfolio from "./WorkPortfolio";

//Import images
import img1 from "../../assets/images/works/img1.jpg";
import img2 from "../../assets/images/works/img2.jpg";
import img3 from "../../assets/images/works/img3.jpg";
import img4 from "../../assets/images/works/img4.jpg";
import img5 from "../../assets/images/works/img5.jpg";
import img6 from "../../assets/images/works/img6.jpg";
import img7 from "../../assets/images/works/img7.jpg";
import img8 from "../../assets/images/works/img8.jpg";
import img9 from "../../assets/images/works/img9.jpg";
import img10 from "../../assets/images/works/img10.jpg";
import img11 from "../../assets/images/works/img11.jpg";
import img12 from "../../assets/images/works/img12.jpg";
import { Link } from "react-router-dom";

const Home = () => {
  const workDetails = [
    {
      id: 1,
      img: img1,
      title: "Iphone Mockup",
      tagName: "Branding",
      category: "Branding",
    },
    {
      id: 2,
      img: img3,
      title: "Mockup Collection",
      tagName: "Mockup",
      category: "Designing",
    },
    {
      id: 3,
      img: img2,
      title: "Abstract Images",
      tagName: "Abstract",
      category: "Photography",
    },
    {
      id: 4,
      img: img4,
      title: "Yellow Background with Books",
      tagName: "Books",
      category: "Development",
    },
    {
      id: 5,
      img: img6,
      title: "Company V-card",
      tagName: "V-card",
      category: "Branding",
    },
    {
      id: 6,
      img: img9,
      title: "Mockup Box with Paints",
      tagName: "Photography",
      category: "Branding",
    },
    {
      id: 7,
      img: img8,
      title: "Coffee Cup",
      tagName: "Cups",
      category: "Designing",
    },
    {
      id: 8,
      img: img7,
      title: "Yellow Background with Books",
      tagName: "Books",
      category: "Development",
    },
    {
      id: 9,
      img: img12,
      title: "Pen and Article",
      tagName: "Article",
      category: "Development",
    },
    {
      id: 10,
      img: img10,
      title: "Yellow Background with Books",
      tagName: "Books",
      category: "Development",
    },
    {
      id: 11,
      img: img11,
      title: "Coffee Cup",
      tagName: "Cups",
      category: "Designing",
    },
    {
      id: 12,
      img: img5,
      title: "Yellow Background with Books",
      tagName: "Books",
      category: "Development",
    },
  ];

  return (
    <React.Fragment>
      <section
        className="hero position-relative overflow-hidden align-items-center"
        id="home"
      >
        <Container>
          <Row>
            <Col lg={9}>
              <h2 className="text-dark fw-light lh-base mt-5">
                Founded and based in{" "}
                <span className="fw-medium title">New York</span>, We are wozia,
                a design and branding agency with partners worldwide.
              </h2>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section">
        <Container>
          <WorkPortfolio workDetails={workDetails} />
          <Row>
            <Col lg={12}>
              <div className="text-center mt-5">
                <Link to="/work" className="btn btn-primary me-2">
                  More Works <i className="mdi mdi-arrow-right ms-1"></i>
                </Link>
                <Link to="/contact" className="btn btn-primary">
                  Get In Touch <i className="mdi mdi-lifebuoy ms-1"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Home;
