import { Icon } from "@ailibs/feather-react-ts";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

//import images
import work from "../../assets/images/works/img9.jpg";

const WorkDetailsPage = () => {
  return (
    <React.Fragment>
      <section className="sm-section">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <img
                src={work}
                alt="about-img"
                className="img-fluid rounded-3 pe-lg-4"
              />
            </Col>
            <Col lg={6}>
              <div className="d-flex align-items-center py-3">
                <h6 className="me-4">
                  Client :{" "}
                  <span className="fw-normal text-muted ms-1">
                    Jhon Thomsoon
                  </span>
                </h6>
                <h6>
                  Year : <span className="fw-normal text-muted ms-1">2021</span>
                </h6>
              </div>

              <Row className="align-items-center border-top py-4">
                <Col sm={4} className="py-sm-0 py-3">
                  <h5>3026</h5>
                  <p className="text-muted fs-15 mb-0">Projects Completed</p>
                </Col>
                <Col sm={4} className="py-sm-0 py-3">
                  <h5>568</h5>
                  <p className="text-muted fs-15 mb-0">Awards receive</p>
                </Col>
                <Col sm={4} className="py-sm-0 py-3">
                  <h5>7089</h5>
                  <p className="text-muted fs-15 mb-0">Happy Clients</p>
                </Col>
              </Row>
              <p className="text-muted fs-15">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable.If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks.
              </p>

              <Link to="#" className="btn btn-primary mt-3">
                Get More
                <Icon name="arrow-right" className="icon-xs" />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default WorkDetailsPage;
