import { Icon } from "@ailibs/feather-react-ts";
import React from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

//import images
import blog1 from "../../assets/images/blog/blog-1.jpg";
import blog2 from "../../assets/images/blog/blog-2.jpg";
import blog3 from "../../assets/images/blog/blog-3.jpg";

const List = () => {
  return (
    <React.Fragment>
      <Col lg={8} md={6}>
        <article className="post position-relative pb-5">
          <div className="date-box rounded-3 shadow text-center bg-light position-absolute top-0 start-0 p-2 ms-3 mt-3">
            <h6 className="fs-14 fw-medium mb-0">25-3-2021</h6>
          </div>
          <div className="post-preview mb-4">
            <Link to="/single-post">
              <img src={blog1} alt="" className="img-fluid rounded-3" />
            </Link>
          </div>
          <div className="post">
            <div className="d-flex align-items-center gap-3">
              <p className="fs-15 text-muted mb-0">
                <Icon name="message-square" className="icon-xs me-1" />
                <Link to="#" className="text-muted">
                  364
                </Link>
              </p>
              <p className="fs-15 text-muted mb-0">
                <Icon name="eye" className="icon-xs me-1" />
                <Link to="#" className="text-muted">
                  56.8 K
                </Link>
              </p>
            </div>
            <h6 className="fs-22 fw-semibold my-3 post-title">
              <Link to="/single-post">Beautiful day with friends in paris</Link>
            </h6>
            <p className="text-muted">
              Whether an identity or campaign, we make your brand visible,
              relevant and effective by placing the digital at the center of its
              ecosystem, without underestimating the power of traditional media.
              Whether an identity or campaign, we make your brand visible.
            </p>
            <Link to="#" className="fw-medium text-dark">
              Read More <Icon name="arrow-right" className="icon-xs icon" />
            </Link>
          </div>
        </article>

        <article className="post position-relative pb-5">
          <div className="date-box rounded-3 shadow text-center bg-light position-absolute top-0 start-0 p-2 ms-3 mt-3">
            <h6 className="fs-14 fw-medium mb-0">16-11-2021</h6>
          </div>
          <div className="post-preview mb-4">
            <Link to="/single-post">
              <img src={blog2} alt="" className="img-fluid rounded-3" />
            </Link>
          </div>
          <div className="post">
            <div className="d-flex align-items-center gap-3">
              <p className="fs-15 text-muted mb-0">
                <Icon name="message-square" className="icon-xs me-1" />
                <Link to="#" className="text-muted">
                  897
                </Link>
              </p>
              <p className="fs-15 text-muted mb-0">
                <Icon name="eye" className="icon-xs me-1" />
                <Link to="#" className="text-muted">
                  99.2 K
                </Link>
              </p>
            </div>
            <h6 className="fs-22 fw-semibold my-3 post-title">
              <Link to="/single-post">
                What makes the best co-working space?
              </Link>
            </h6>
            <p className="text-muted">
              Whether an identity or campaign, we make your brand visible,
              relevant and effective by placing the digital at the center of its
              ecosystem, without underestimating the power of traditional media.
              Whether an identity or campaign, we make your brand visible.
            </p>
            <Link to="#" className="fw-medium text-dark">
              Read More <Icon name="arrow-right" className="icon-xs icon" />
            </Link>
          </div>
        </article>

        <article className="post position-relative pb-5">
          <div className="date-box rounded-3 shadow text-center bg-light position-absolute top-0 start-0 p-2 ms-3 mt-3">
            <h6 className="fs-14 fw-medium mb-0">05-08-2021</h6>
          </div>
          <div className="post-preview mb-4">
            <Link to="/single-post">
              <img src={blog3} alt="" className="img-fluid rounded-3" />
            </Link>
          </div>
          <div className="post">
            <div className="d-flex align-items-center gap-3">
              <p className="fs-15 text-muted mb-0">
                <Icon name="message-square" className="icon-xs me-1" />
                <Link to="#" className="text-muted">
                  447
                </Link>
              </p>
              <p className="fs-15 text-muted mb-0">
                <Icon name="eye" className="icon-xs me-1" />
                <Link to="#" className="text-muted">
                  68.1 K
                </Link>
              </p>
            </div>
            <h6 className="fs-22 fw-semibold my-3 post-title">
              <Link to="/single-post">
                Co-Working Spaces and Their Benefits
              </Link>
            </h6>
            <p className="text-muted">
              Whether an identity or campaign, we make your brand visible,
              relevant and effective by placing the digital at the center of its
              ecosystem, without underestimating the power of traditional media.
              Whether an identity or campaign, we make your brand visible.
            </p>
            <Link to="#" className="fw-medium text-dark">
              Read More <Icon name="arrow-right" className="icon-xs icon" />
            </Link>
          </div>
        </article>
      </Col>
    </React.Fragment>
  );
};

export default List;
