import { Icon } from "@ailibs/feather-react-ts";
import React from "react";
import { Card, CardBody, Col } from "reactstrap";

//import images
import visa from "../../assets/images/visa-bg.png";

const PaymentInfo = () => {
  return (
    <React.Fragment>
      <Col lg={5} className="mt-5">
        <div className="shadow rounded-3 p-4">
          <h5 className="fw-semibold fs-18 mb-3"> Payment Info</h5>
          <Card
            className="bg-secondary position-relative rounded-3"
            style={{ background: `url(${visa}) center center` }}
          >
            <CardBody>
              <i className="mdi mdi-contactless-payment text-white fs-3"></i>
              <img
                className="img-fluid float-end mt-2"
                src={visa}
                width="60"
                alt=""
              />
              <p className="text-white-50 text-uppercase fs-13 mt-4 mb-2">
                Card Holder Name
              </p>
              <h6 className="text-white fw-medium">John Smith</h6>
              <div className="d-flex align-items-center">
                <div className="pay-text">
                  <p className="text-white-50 text-uppercase fs-13 mt-4 mb-2">
                    Card Number
                  </p>
                  <h6 className="text-white fw-medium mb-3">
                    **** **** **** 1234
                  </h6>
                </div>
                <div className="pay-text ms-auto">
                  <p className="text-white-50 text-uppercase fs-13 mt-4 mb-2">
                    Valid Till
                  </p>
                  <h6 className="text-white fw-medium mb-3">06/26</h6>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className="bg-light rounded-3 p-4 mt-4">
            <div className="form-check fs-16">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label
                className="form-check-label fw-medium text-dark ms-1"
                htmlFor="flexRadioDefault1"
              >
                Credit/Debit Card{" "}
              </label>
              <span className="text-muted">
                <Icon name="check" className="icon-xs float-end" />
              </span>
            </div>
          </div>
          <div className="bg-light rounded-3 p-4 mt-4">
            <div className="form-check fs-16">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
              />
              <label
                className="form-check-label fw-medium text-dark ms-1"
                htmlFor="flexRadioDefault2"
              >
                {" "}
                Cash on delivery
              </label>
              <span className="text-muted">
                <Icon name="check" className="icon-xs float-end" />
              </span>
            </div>
          </div>
          <div className="bg-light rounded-3 p-4 mt-4">
            <div className="form-check fs-16">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault3"
                defaultChecked
              />
              <label
                className="form-check-label fw-medium text-dark ms-1"
                htmlFor="flexRadioDefault3"
              >
                {" "}
                Net Banking
              </label>
              <span className="text-primary">
                <Icon name="check" className="icon-xs float-end" />
              </span>
            </div>
          </div>
          <div className="bg-light rounded-3 p-4 mt-4">
            <div className="form-check fs-16">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault4"
              />
              <label
                className="form-check-label fw-medium text-dark ms-1"
                htmlFor="flexRadioDefault4"
              >
                UPI
              </label>
              <span className="text-muted">
                <Icon name="check" className="icon-xs float-end" />
              </span>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default PaymentInfo;
