import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "@ailibs/feather-react-ts";

//import images
import product1 from "../../assets/images/products/pro-1.jpg";
import product2 from "../../assets/images/products/pro-5.jpg";
import product3 from "../../assets/images/products/pro-3.jpg";
import product4 from "../../assets/images/products/pro-4.jpg";

const Detailspage = () => {
  return (
    <React.Fragment>
      <section className="sm-section">
        <Container>
          <Row>
            <Col lg={12}>
              <Row>
                <Col md={5} className="mt-4">
                  <div className="position-relative">
                    <img
                      className="img-fluid rounded-3"
                      src={product1}
                      alt=""
                    />
                    <span className="text-dark badge bg-light rounded-3 fs-14 fw-bold position-absolute top-0 start-0 mt-2 ms-2 py-2">
                      -18%
                    </span>
                  </div>
                  <Row>
                    <Col sm={4} className="mt-4">
                      <Link to="">
                        <img
                          className="img-fluid rounded"
                          src={product2}
                          alt=""
                        />
                      </Link>
                    </Col>
                    <Col sm={4} className="mt-4">
                      <Link to="">
                        <img
                          className="img-fluid rounded"
                          src={product3}
                          alt=""
                        />
                      </Link>
                    </Col>
                    <Col sm={4} className="mt-4">
                      <Link to="">
                        <img
                          className="img-fluid rounded"
                          src={product4}
                          alt=""
                        />
                      </Link>
                    </Col>
                  </Row>
                </Col>
                <Col md={7} className="mt-4">
                  <div className="pro-detail-content">
                    <div className="price my-3">
                      <ins className="pe-2 fs-18 text-success fw-semibold text-decoration-none">
                        $209.00
                      </ins>
                      <del className="text-muted fs-18">$230.00</del>
                    </div>

                    <p className="text-muted fs-16 my-3">
                      The full monty brilliant young delinquent burke naff
                      baking cakes the wireless argy-bargy smashing, squiffy
                      chimney pot I he nicked it twit brolly spend a penny he
                      legged it.
                    </p>
                    <div className="rating my-3">
                      <i className="mdi mdi-star fs-20 text-warning d-inline-block"></i>
                      <h5 className="d-inline-block fs-16 mb-0">5.0</h5>
                    </div>

                    <div className="details mb-4">
                      <ul className="list-unstyled">
                        <li className="text-muted py-1">
                          <Icon name="arrow-right" className="icon-xxs me-2" />
                          Generally delivered in 7 - 11 days
                        </li>
                        <li className="text-muted py-1">
                          <Icon name="arrow-right" className="icon-xxs me-2" />
                          Warranty Period (in years):1
                        </li>
                        <li className="text-muted py-1">
                          <Icon name="arrow-right" className="icon-xxs me-2" />
                          Number of Focus Points:3
                        </li>
                        <li className="text-muted py-1">
                          <Icon name="arrow-right" className="icon-xxs me-2" />7
                          Days Replacement
                        </li>
                      </ul>
                      <ul className="list-unstyled list-inline"></ul>
                    </div>
                    <div className="qty mb-4">
                      <h6 className="d-inline-block fw-semibold">
                        Select Items :
                      </h6>
                      <select
                        className="form-select border d-inline-block ms-4 w-25"
                        id="autoSizingSelect"
                      >
                        <option>1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </div>
                    <div className="color mb-4">
                      <h6 className="d-inline-block fw-semibold">Color :</h6>
                      <ul className="d-inline-block list-unstyled list-inline align-middle ms-3 mb-0">
                        <li className="list-inline-item me-0">
                          <Link to="#">
                            <i className="mdi mdi-invert-colors fs-5 text-success"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item me-0">
                          <Link to="#">
                            <i className="mdi mdi-invert-colors fs-5 text-primary"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item me-0">
                          <Link to="#">
                            <i className="mdi mdi-invert-colors fs-5 text-danger"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item me-0">
                          <Link to="#">
                            <i className="mdi mdi-invert-colors fs-5 text-warning"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="#">
                            <i className="mdi mdi-invert-colors fs-5 text-dark"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="availability d-flex mb-4">
                      <h6 className="fw-semibold">Availability :</h6>
                      <p className="text-muted fs-15 ms-4">In Stock</p>
                    </div>
                    <div className="hover-content">
                      <Link to="/cart" className="btn btn-primary me-2 my-2">
                        {" "}
                        <Icon name="shopping-cart" className="icon-xs me-2" />
                        Add To Cart
                      </Link>
                      <Link to="/cart" className="btn btn-secondary my-2">
                        <Icon name="heart" className="icon-xs me-2" />
                        Wishlist
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Detailspage;
