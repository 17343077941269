import { Icon } from "@ailibs/feather-react-ts";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import logoLight from "../assets/images/logo-light.png";
import withRouter from "../Component/withRouter";

interface FooterProps { }
const Footer = (props: FooterProps) => {
  const [isSwitchToggle, setIsSwitchToggle] = useState<boolean>(false);

  const setTheme = (color: string) => {
    let colorTheme: any = document.getElementById("colorTheme");
    colorTheme.href = "css/colors/" + color + ".css";
  };

  // light/dark mode button

  const toggleThem = () => {
    if (document.head.getAttribute("data-bs-theme") === "light") {
      let colorTheme: any = document.getElementById("app-css");
      colorTheme.href = "css/style-dark.css";
      document.head.setAttribute("data-bs-theme", "dark");      
    } else {
      let colorTheme: any = document.getElementById("app-css");
      colorTheme.href = "css/style.css";
      document.head.setAttribute("data-bs-theme", "light");
    }
  };


  const toggleSwitcher = () => {
    setIsSwitchToggle(!isSwitchToggle);
  };
  return (
    <React.Fragment>
      <footer className="footer">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="footer-logo d-inline-block py-4">
                  <img
                    src={logoLight}
                    className="mx-auto logo-dark"
                    alt=""
                    height="24"
                  />
                </Link>

                <ul className="list-inline social-circle mt-3">
                  <li className="list-inline-item">
                    <Link to="#">
                      <i className="mdi mdi-facebook"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <i className="mdi mdi-twitter"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <i className="mdi mdi-google-plus"></i>{" "}
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <i className="mdi mdi-apple"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <i className="mdi mdi-instagram"></i>
                    </Link>
                  </li>
                </ul>

                <div className="text-white-50 pb-4">
                  {new Date().getFullYear()}
                  &copy; Wozia by{" "}
                  <Link
                    className="text-white-50 text-decoration-underline"
                    target="_blank"
                    to="https://pichforest.com/"
                  >
                    Pichforest
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      <div
        id="style-switcher"
        style={{ left: isSwitchToggle ? "0px" : "-189px" }}
      >
        <div>
          <h3>Select Your Color</h3>
          <ul className="pattern">
            <li>
              <Link
                onClick={() => setTheme("default")}
                className="color1"
                to="#"
              ></Link>
            </li>
            <li>
              <Link
                onClick={() => setTheme("success")}
                className="color2"
                to="#"
              ></Link>
            </li>
            <li>
              <Link
                onClick={() => setTheme("warning")}
                className="color3"
                to="#"
              ></Link>
            </li>
            <li>
              <Link
                onClick={() => setTheme("danger")}
                className="color4"
                to="#"
              ></Link>
            </li>
            <li>
              <Link
                onClick={() => setTheme("info")}
                className="color5"
                to="#"
              ></Link>
            </li>
            <li>
              <Link
                onClick={() => setTheme("amber")}
                className="color6"
                to="#"
              ></Link>
            </li>
          </ul>
        </div>
        <div className="bottom">
          <Link
            onClick={toggleSwitcher}
            to="#"
            className="settings rounded-end text-light"
          >
            <Icon name="droplet" />
          </Link>
        </div>
      </div>

      <Link to="#" id="mode"  className="text-white rounded-end mode-btn" onClick={toggleThem} >
        {/* <i  data-feather="moon"></i> */}
        <Icon className="mode-dark mx-auto" name="moon" />
        <Icon className="bx-spin mode-light" name="sun" />
        {/* <i className="bx-spin mode-light" data-feather="sun"></i> */}
      </Link>
    </React.Fragment>
  );
};

export default withRouter(Footer);
