import { Icon } from "@ailibs/feather-react-ts";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

//import images
import blog from "../../assets/images/blog/blog-2.jpg";
import client1 from "../../assets/images/clients/client-1.jpg";
import client2 from "../../assets/images/clients/client-2.jpg";
import client3 from "../../assets/images/clients/client-3.jpg";

const SingleDetailsPage = () => {
  return (
    <React.Fragment>
      <article className="post position-relative">
        <div className="date-box rounded-3 shadow text-center bg-light position-absolute top-0 start-0 p-2 ms-3 mt-3">
          <h6 className="fs-14 fw-medium mb-0">16-11-2021</h6>
        </div>
        <div className="post-preview mb-4">
          <Link to="/blog-single">
            <img src={blog} alt="" className="img-fluid rounded" />
          </Link>
        </div>
        <div className="post">
          <div className="d-flex align-items-center gap-3">
            <p className="fs-15 text-muted mb-0">
              <Icon name="message-square" className="icon-xs me-1" />
              <Link to="#" className="text-muted">
                897
              </Link>
            </p>
            <p className="fs-15 text-muted mb-0">
              <Icon name="eye" className="icon-xs me-1" />
              <Link to="#" className="text-muted">
                99.2 K
              </Link>
            </p>
          </div>
          <h6 className="fs-22 fw-semibold my-3 post-title">
            <Link to="/single-post">What makes the best co-working space?</Link>
          </h6>
          <p className="text-muted">
            Whether an identity or campaign, we make your brand visible,
            relevant and effective by placing the digital at the center of its
            ecosystem, without underestimating the power of traditional media.
            Whether an identity or campaign, we make your brand visible.
          </p>
          <p className="text-muted">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
          </p>
          <p className="text-muted">
            vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque corrupti quos
            dolores et quas molestias excepturi sint occaecati cupiditate non
            provident, similique sunt in culpa qui.
          </p>

          <Col lg={12} className="my-4">
            <div className="section-title border-start border-primary position-relative">
              <i className="mdi mdi-format-quote-open-outline fs-3 text-primary"></i>
              <h5 className="fw-medium lh-1">We Are Awesome.</h5>
              <p className="text-muted mb-0">
                A business consulting agency is involved in the planning,
                implementation, and education of businesses. We work directly.
              </p>
            </div>
          </Col>
          <p className="text-muted">
            vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque corrupt quas
            molestias excepturi sint occaecati cupiditate non provident.
          </p>
        </div>

        <h6 className="fs-22 fw-semibold border-bottom pb-3 mt-5">Comments</h6>
        <div className="ps-0 pt-3 mt-3">
          <div className="d-sm-flex align-items-top">
            <div className="flex-shrink-0">
              <img
                className="rounded-circle img-thumbnail"
                src={client1}
                alt="img"
                style={{ width: "65px", height: "65px" }}
              />
            </div>
            <div className="flex-grow-1 ms-sm-3">
              <h6 className="mt-sm-0 mt-3 fs-16">Michelle Durant</h6>
              <p className="text-muted">Jun 23, 2021, 11:45 am</p>

              <p className="text-muted fs-15">
                vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores
              </p>
            </div>
            <Link className="text-muted me-3" to="#">
              <i className="icon-xs" data-feather="message-square"></i>
            </Link>
          </div>
        </div>
        <div className="ps-0 pt-3 mt-4">
          <div className="d-sm-flex align-items-top">
            <div className="flex-shrink-0">
              <img
                className="rounded-circle img-thumbnail"
                src={client2}
                alt="img"
                style={{ width: "65px", height: "65px" }}
              />
            </div>
            <div className="flex-grow-1 ms-sm-3">
              <h6 className="mt-sm-0 mt-3 fs-16">Steven Billups</h6>
              <p className="text-muted">Jun 24, 2021, 10:05 am</p>

              <p className="text-muted fs-15">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout
              </p>

              <div className="d-sm-flex align-items-top mt-4">
                <div className="flex-shrink-0">
                  <img
                    className="rounded-circle img-thumbnail"
                    src={client3}
                    alt="img"
                    style={{ width: "65px", height: "65px" }}
                  />
                </div>
                <div className="flex-grow-1 ms-sm-3">
                  <h6 className="mt-sm-0 mt-3 fs-16">Harriet Townsend</h6>
                  <p className="text-muted">Jun 26, 2021, 01:25 pm</p>

                  <p className="text-muted fs-15">
                    variations of passages of Lorem Ipsum available, but the
                    majority have suffered alteration
                  </p>
                </div>
              </div>
            </div>
            <Link className="text-muted me-3" to="#">
              <i className="icon-xs" data-feather="message-square"></i>
            </Link>
          </div>
        </div>

        <h6 className="fs-22 fw-semibold border-bottom pb-3 mt-5">
          Leave a Reply
        </h6>
        <form action="#" method="post" className="mt-4">
          <Row className="g-3">
            <div className="col-lg-6">
              <div className="mb-3">
                <input
                  id="author"
                  className="form-control"
                  placeholder="Name*"
                  name="author"
                  type="text"
                  required
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <input
                  id="email"
                  className="form-control"
                  placeholder="Email*"
                  name="email"
                  type="text"
                  required
                />
              </div>
            </div>
          </Row>
          <Row className="g-3">
            <div className="col-lg-12">
              <div className="mb-3">
                <input
                  id="subject"
                  className="form-control"
                  placeholder="Website"
                  name="subject"
                  type="text"
                />
              </div>
            </div>
          </Row>

          <Row className="g-3">
            <div className="col-lg-12">
              <div className="mb-3">
                <textarea
                  id="comment"
                  className="form-control"
                  rows={5}
                  placeholder="Your Message*"
                  name="comment"
                  required
                ></textarea>
              </div>
            </div>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="form-group">
                <button
                  name="submit"
                  type="submit"
                  id="submit"
                  className="btn btn-dark"
                >
                  Post Comment <Icon name="arrow-right" className="icon-xxs" />
                </button>
              </div>
            </Col>
          </Row>
        </form>

        <ul className="list-unstyled share-link list-inline mt-5 border-bottom mb-4">
          <li className="list-inline-item">
            <h6 className="fw-medium fs-14 text-uppercase me-2">Share :</h6>
          </li>
          <li className="list-inline-item border-end pe-3">
            <Link to="#">Facebook</Link>
          </li>
          <li className="list-inline-item border-end pe-3">
            <Link to="#">Twitter</Link>
          </li>
          <li className="list-inline-item border-end pe-3">
            <Link to="#">Linked In</Link>
          </li>
          <li className="list-inline-item">
            <Link to="#">Instagram</Link>
          </li>
        </ul>
      </article>
    </React.Fragment>
  );
};

export default SingleDetailsPage;
