import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Clients from "../About/Clients";
import LeftSidebar from "./LeftSidebar";
import MenuItem from "./MenuItem";
import Pagination from "./Pagination";
import { products } from "./product";
import { Icon } from "@ailibs/feather-react-ts";

const Products = () => {
  return (
    <React.Fragment>
      <section>
        <Container>
          <Row>
            <Col lg={9}>
              <div className="page-title">
                <h2 className="text-dark fw-light lh-base mt-5">
                  Launching Spring{" "}
                  <span className="fw-medium title">Summer'21</span>
                </h2>
                <p className="fs-16 text-muted mb-0">
                  Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                  consequat ipsum, nec sagittis sem nibh id elit. Proin gravida
                  nibh vel velit auctor Aenean sollicitudin, adipisicing elit
                  sed lorem quis bibendum auctor.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sm-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="border-bottom">
                <Row className="align-items-center pb-sm-3 pb-4">
                  <Col lg={8} md={6} className="my-md-0 my-2">
                    <p className="text-muted mb-0 py-sm-0 py-2">
                      Showing 1–9 of 15 results
                    </p>
                  </Col>
                  <Col lg={4} md={6} className="my-md-0 my-2">
                    <form>
                      <Row className="mb-0 mt-md-4 mt-lg-0">
                        <h5 className="col-sm-3 col-form-label">Sort By :</h5>
                        <Col sm={9} className="ps-0">
                          <select
                            className="form-select bg-light text-muted border fs-14 py-2 ms-sm-0 ms-1"
                            aria-label="Default select example"
                          >
                            <option>Most Popular </option>
                            <option defaultValue="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="sidebar shadow rounded-3 py-4 mt-4">
                <div className="mb-4 ms-4">
                  <Link to="/products">
                    <Icon name="grid" className="text-muted me-2" />
                  </Link>
                  <Link to="/product-list">
                    <Icon name="list" className="text-primary" />
                  </Link>
                </div>
                <LeftSidebar />
              </div>
            </Col>
            <Col md={9}>
              <Row>
                <MenuItem products={products} />
              </Row>
              {/* import Pagination  */}
              <Pagination />
            </Col>
          </Row>
        </Container>
      </section>

      {/* import Client Section */}
      <Clients sectionTitle={false} />
    </React.Fragment>
  );
};

export default Products;
