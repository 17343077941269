//import images
import blog1 from "../../assets/images/blog/blog-1.jpg";
import blog2 from "../../assets/images/blog/blog-2.jpg";
import blog3 from "../../assets/images/blog/blog-3.jpg";
import blog4 from "../../assets/images/blog/blog-4.jpg";
import blog5 from "../../assets/images/blog/blog-5.jpg";
import blog6 from "../../assets/images/blog/blog-6.jpg";

const blogItem = [
    {
        id : 1,
        img : blog1,
        date : "25-3-2021",
        message : "364",
        viewers : "56.8 K",
        title : "Beautiful day with friends in paris",
        desc : "Whether an identity or campaign, we make your brand visible, relevant and effective by placing the digital at the center of its ecosystem traditional media.",      
    },
    {
        id : 2,
        img : blog2,
        date : "15-04-2019",
        message : "567",
        viewers : "29.3 K",
        title : "Versions have evolved over the years of the lorem",
        desc : "Whether an identity or campaign, we make your brand visible, relevant and effective by placing the digital at the center of its ecosystem traditional media.",      
    },
    {
        id : 3,
        img : blog3,
        date : "21-03-2021",
        message : "664",
        viewers : "93.2 K",
        title : "Many desktop publishing packages goods",
        desc : "Whether an identity or campaign, we make your brand visible, relevant and effective by placing the digital at the center of its ecosystem traditional media.",      
    },
    {
        id : 4,
        img : blog4,
        date : "03-07-2021",
        message : "331",
        viewers : "99.5 K",
        title : "It's a long established fact that a reader",
        desc : "Whether an identity or campaign, we make your brand visible, relevant and effective by placing the digital at the center of its ecosystem traditional media.",      
    },
    {
        id : 5,
        img : blog5,
        date : "03-07-2021",
        message : "331",
        viewers : "99.5 K",
        title : "It's a long established fact that a reader",
        desc : "Whether an identity or campaign, we make your brand visible, relevant and effective by placing the digital at the center of its ecosystem traditional media.",      
    },
    {
        id : 6,
        img : blog6,
        date : "25-3-2021",
        message : "331",
        viewers : "99.5 K",
        title : "It's a long established fact that a reader",
        desc : "Whether an identity or campaign, we make your brand visible, relevant and effective by placing the digital at the center of its ecosystem traditional media.",      
    },
];

export {blogItem};