import React from "react";
import { Link } from "react-router-dom";

//import images
import client from "../../assets/images/clients/client-1.jpg";
import client1 from "../../assets/images/clients/client-2.jpg";

const Reviews = () => {
  return (
    <React.Fragment>
      <h6 className="lh-base fw-medium">5 Ratings & 2 Review</h6>
      <ul className="list-unstyled my-4">
        <li className="list-inline d-flex py-3">
          <div>
            <Link to="#">
              <img
                className="rounded-circle img-thumbnail"
                src={client}
                alt=""
              />
            </Link>
          </div>
          <div className="ps-4">
            <h6 className="d-inline-block fs-16 mb-0">John Barrows</h6>
            <div className="rating d-inline-block ps-sm-4">
              <i className="mdi mdi-star fs-16 text-warning d-inline-block"></i>
              <h5 className="d-inline-block fs-16">5.0</h5>
            </div>
            <p className="text-muted">24th January, 2021</p>
            <p className="text-muted fs-16">
              Animi tempora pellentesque, tristique gravida dui, deleniti quod
              repellendus voluptatum, aptent adipisci young delinquent burke
              naff baking cakes the wireless argy-bargy smashpend a penny he
              legged it.
            </p>
          </div>
        </li>
        <li className="list-inline d-flex py-3">
          <div>
            <Link to="#">
              <img
                className="rounded-circle img-thumbnail"
                src={client1}
                alt=""
              />
            </Link>
          </div>
          <div className="ps-4">
            <h6 className="d-inline-block fs-16 mb-0">James Marks</h6>
            <div className="rating d-inline-block ps-sm-4">
              <i className="mdi mdi-star fs-16 text-warning d-inline-block"></i>
              <h5 className="d-inline-block fs-16">4.9</h5>
            </div>
            <p className="text-muted">2nd January, 2021</p>
            <p className="text-muted fs-16">
              Animi tempora pellentesque, tristique gravida dui, deleniti quod
              repellendus voluptatum, aptent adipisci young delinquent burke
              naff baking cakes the wireless argy-bargy smashpend a penny he
              legged it.
            </p>
          </div>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default Reviews;
