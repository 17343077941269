import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const DelieveryAddress = () => {
  return (
    <React.Fragment>
      <div>
        <h5 className="fw-semibold fs-18 mb-3">Delievery Address </h5>

        <Row>
          <Col md={12}>
            <div className="border rounded position-relative p-4">
              <h5 className="fw-semibold fs-16">
                Stephen Guterez
                <Link to="#">
                  <i className="mdi mdi-square-edit-outline fs-22 text-muted position-absolute top-0 end-0 mt-2 me-2"></i>
                </Link>
              </h5>
              <p className="my-2 mt-3 text-muted fs-15">
                4624 Meadowcrest Lane Pikeville, KY 41501
              </p>
              <p className="my-2 text-muted fs-15">Call : +00 410-790-2942</p>
              <p className="my-2 text-muted fs-15">
                Email : loremipsum@duy.com
              </p>
            </div>
            <Link to="#" className="btn btn-secondary mt-3">
              <i className="mdi mdi-plus-circle"></i> Add Address
            </Link>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DelieveryAddress;
