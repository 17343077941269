import React from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

//import images
import product1 from "../../assets/images/products/pro-1.jpg";
import product2 from "../../assets/images/products/pro-3.jpg";
import product3 from "../../assets/images/products/pro-9.jpg";

const ProductListPage = () => {
  return (
    <React.Fragment>
      <Col md={5}>
        <div className="product-item mt-4">
          <div className="product-list-img rounded-3 position-relative">
            <img className="img-fluid rounded-3" src={product1} alt="" />
            <span className="text-dark badge bg-light rounded-3 fs-14 fw-bold position-absolute top-0 start-0 mt-2 ms-2 py-2">
              -18%
            </span>
          </div>
        </div>
      </Col>
      <Col md={7}>
        <div className="pro-list-content my-3">
          <div className="price d-inline-block">
            <ins className="pe-1 fs-16 fw-semibold text-decoration-none text-dark">
              $209.00
            </ins>
            <del className="text-muted fs-16">$230.00</del>
          </div>
          <div className="rating d-inline-block ps-4">
            <i className="mdi mdi-star fs-20 text-warning d-inline-block"></i>
            <h5 className="d-inline-block fs-16">5.0</h5>
          </div>

          <Link to="/product-detail">
            <h6 className="fw-semibold lh-base fs-18 mt-3">
              Maxbell USB Rechargeable Electronic
            </h6>
          </Link>
          <p className="text-muted my-3">
            The full monty brilliant young delinquent burke naff baking cakes
            the wireless argy-bargy smashing, squiffy chimney pot I he nicked it
            twit brolly spend a penny he legged it.
          </p>
          <div className="hover-content w-100 py-2">
            <Link to="/cart">
              <i className="mdi mdi-cart-outline fs-22 me-3 pe-3 fw-bolder pro-icon border-end"></i>
            </Link>
            <Link to="/product-detail">
              <i className="mdi mdi-eye-outline fs-22 me-3 pe-3 fw-bolder pro-icon border-end active"></i>
            </Link>
            <Link to="/cart">
              <i className="mdi mdi-heart-outline fs-22 fw-bolder pro-icon"></i>
            </Link>
          </div>
        </div>
      </Col>
      <Col md={5}>
        <div className="product-item mt-4">
          <div className="product-list-img rounded-3 position-relative">
            <img className="img-fluid rounded-3" src={product2} alt="" />
          </div>
        </div>
      </Col>
      <Col md={7}>
        <div className="pro-list-content my-3">
          <div className="price d-inline-block">
            <ins className="pe-1 fs-16 fw-semibold text-decoration-none text-dark">
              $309.00
            </ins>
            <del className="text-muted fs-16">$230.00</del>
          </div>
          <div className="rating d-inline-block ps-4">
            <i className="mdi mdi-star fs-20 text-warning d-inline-block"></i>
            <h5 className="d-inline-block fs-16">5.0</h5>
          </div>

          <Link to="/product-detail">
            <h6 className="fw-semibold lh-base fs-18 mt-3">
              PRd PU Tan Casual regular Walletious
            </h6>
          </Link>
          <p className="text-muted my-3">
            The full monty brilliant young delinquent burke naff baking cakes
            the wireless argy-bargy smashing, squiffy chimney pot I he nicked it
            twit brolly spend a penny he legged it.
          </p>
          <div className="hover-content w-100 py-2">
            <Link to="/cart">
              <i className="mdi mdi-cart-outline fs-22 me-3 pe-3 fw-bolder pro-icon border-end"></i>
            </Link>
            <Link to="/product-detail">
              <i className="mdi mdi-eye-outline fs-22 me-3 pe-3 fw-bolder pro-icon border-end active"></i>
            </Link>
            <Link to="/cart">
              <i className="mdi mdi-heart-outline fs-22 fw-bolder pro-icon"></i>
            </Link>
          </div>
        </div>
      </Col>
      <Col md={5}>
        <div className="product-item mt-4">
          <div className="product-list-img rounded-3 position-relative">
            <img className="img-fluid rounded-3" src={product3} alt="" />
            <span className="text-dark badge bg-light rounded-3 fs-14 fw-bold position-absolute top-0 start-0 mt-2 ms-2 py-2">
              -24%
            </span>
          </div>
        </div>
      </Col>
      <Col md={7}>
        <div className="pro-list-content my-3">
          <div className="price d-inline-block">
            <ins className="pe-1 fs-16 fw-semibold text-decoration-none text-dark">
              $109.00
            </ins>
            <del className="text-muted fs-16">$230.00</del>
          </div>
          <div className="rating d-inline-block ps-4">
            <i className="mdi mdi-star fs-20 text-warning d-inline-block"></i>
            <h5 className="d-inline-block fs-16">5.0</h5>
          </div>

          <Link to="/product-detail">
            <h6 className="fw-semibold lh-base fs-18 mt-3">
              Children Decorated Shoes Cotton Green
            </h6>
          </Link>
          <p className="text-muted my-3">
            The full monty brilliant young delinquent burke naff baking cakes
            the wireless argy-bargy smashing, squiffy chimney pot I he nicked it
            twit brolly spend a penny he legged it.
          </p>
          <div className="hover-content w-100 py-2">
            <Link to="/cart">
              <i className="mdi mdi-cart-outline fs-22 me-3 pe-3 fw-bolder pro-icon border-end"></i>
            </Link>
            <Link to="/product-detail">
              <i className="mdi mdi-eye-outline fs-22 me-3 pe-3 fw-bolder pro-icon border-end active"></i>
            </Link>
            <Link to="/cart">
              <i className="mdi mdi-heart-outline fs-22 fw-bolder pro-icon"></i>
            </Link>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default ProductListPage;
