import React from "react";
import { Col, Container, Row } from "reactstrap";
import Clients from "../About/Clients";
import DelieveryAddress from "./DelieveryAddress";
import OrderDetails from "./OrderDetails";
import OrderSummary from "./OrderSummary";
import PaymentInfo from "./PaymentInfo";

const Checkout = () => {
  return (
    <React.Fragment>
      <section>
        <Container>
          <Row>
            <Col lg={9}>
              <div className="page-title">
                <h2 className="text-dark fw-light lh-base mt-5">
                  A complete payment platform for your
                  <span className="fw-medium title"> safe payment.</span>
                </h2>
                <p className="fs-16 text-muted mb-0">
                  Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                  consequat ipsum, nec sagittis sem nibh id elit. Proin gravida
                  nibh vel velit auctor Aenean sollicitudin, adipisicing elit
                  sed lorem quis bibendum auctor.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sm-section">
        <Container>
          <div className="row">
            <Col lg={7} className="mt-5">
              {/* import DelieveryAddress */}
              <DelieveryAddress />

              {/* import OrderSummary  */}
              <OrderSummary />
            </Col>

            {/* import PaymentInfo */}
            <PaymentInfo />
          </div>

          {/* import Order Details */}
          <OrderDetails />
        </Container>
      </section>

      {/* import Client Section */}
      <Clients sectionTitle={true} />
    </React.Fragment>
  );
};

export default Checkout;
