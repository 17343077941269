import React from "react";
import { Col, Container, Row } from "reactstrap";
import Clients from "../About/Clients";

const Contact = () => {
  return (
    <React.Fragment>
      <section>
        <Container>
          <Row>
            <Col lg={9}>
              <h2 className="text-dark fw-light lh-base mt-5">
                Let's Keep In <span className="title fw-medium">Touch</span>
              </h2>
              <p className="fs-16 text-muted mb-0">
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                consequat ipsum, nec sagittis sem nibh id elit. Proin gravida
                nibh vel velit auctor Aenean sollicitudin, adipisicing elit sed
                lorem quis bibendum auctor.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sm-section">
        <Container>
          <Row>
            <Col lg={4} className="mt-5">
              <div>
                <h5 className="text-muted fw-medium fs-14">Email Address</h5>
                <p className="text-dark fs-16">support.yourdomain@email.com</p>
              </div>
              <div className="mt-4">
                <h5 className="text-muted fw-medium fs-14">Telephone</h5>
                <p className="text-dark fs-16">+123 45 56 879</p>
              </div>
              <div className="mt-4">
                <h5 className="text-muted fw-medium fs-14">Address</h5>
                <p className="text-dark fs-16">
                  09 Design Street, Downtown Victoria, <br />
                  Australia
                </p>
              </div>
            </Col>
            <Col lg={8} className="mt-5">
              <form method="post" name="myForm">
                <span id="error-msg"></span>
                <Row className="g-3">
                  <Col lg={6}>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Your name"
                      />
                      <label className="text-muted" htmlFor="name">
                        Your name
                      </label>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Your email"
                        id="email"
                      />
                      <label className="text-muted" htmlFor="email">
                        Email address
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Subject"
                        id="subject"
                      />
                      <label className="text-muted" htmlFor="subject">
                        Subject
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="form-floating mb-3">
                      <textarea
                        className="form-control"
                        placeholder="Your Message...."
                        style={{ height: "100px" }}
                        id="comments"
                      ></textarea>
                      <label className="text-muted" htmlFor="comments">
                        Your message
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      className="submitBnt btn btn-primary rounded"
                      value="Send message"
                    />
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </section>

      {/* import Clients */}
      <Clients sectionTitle={true} />
    </React.Fragment>
  );
};

export default Contact;
