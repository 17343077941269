import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

const WorkPortfolio = ({ workDetails }: any) => {
  const [displayCategory, setCategory] = useState<string>("All");

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col lg={12} className="filters-group-wrap mb-3">
          <div className="filters-group">
            <ul className="container-filter mb-0 categories-filter list-unstyled filter-options">
              <li
                onClick={() => setCategory("All")}
                className={
                  displayCategory === "All"
                    ? "list-inline-item categories position-relative my-sm-0 my-2 active"
                    : "list-inline-item categories position-relative my-sm-0 my-2"
                }
              >
                <Link to="#">All</Link>
              </li>
              <li
                onClick={() => setCategory("Branding")}
                className={
                  displayCategory === "Branding"
                    ? "list-inline-item categories position-relative my-sm-0 my-2 active"
                    : "list-inline-item categories position-relative my-sm-0 my-2"
                }
              >
                <Link to="#">Branding</Link>
              </li>
              <li
                onClick={() => setCategory("Designing")}
                className={
                  displayCategory === "Designing"
                    ? "list-inline-item categories position-relative my-sm-0 my-2 active"
                    : "list-inline-item categories position-relative my-sm-0 my-2"
                }
              >
                <Link to="#">Designing</Link>
              </li>
              <li
                onClick={() => setCategory("Photography")}
                className={
                  displayCategory === "Photography"
                    ? "list-inline-item categories position-relative my-sm-0 my-2 active"
                    : "list-inline-item categories position-relative my-sm-0 my-2"
                }
              >
                <Link to="#">Photography</Link>
              </li>
              <li
                onClick={() => setCategory("Development")}
                className={
                  displayCategory === "Development"
                    ? "list-inline-item categories position-relative my-sm-0 my-2 active"
                    : "list-inline-item categories position-relative my-sm-0 my-2"
                }
              >
                <Link to="#">Development</Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <div id="grid" className="row">
        {workDetails
          .filter(
            ({ category }: any) =>
              displayCategory === category || displayCategory === "All"
          )
          .map(({ img, title, tagName }: any, key: number) => (
            <Col
              lg={4}
              md={6}
              xs={12}
              className="spacing picture-item"
              key={key}
            >
              <Card className="border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                <CardBody className="p-0">
                  <Link to="#" className="d-inline-block" title="">
                    <img src={img} className="img-fluid rounded" alt="" />
                  </Link>
                  <div className="content bg-light p-3 shadow">
                    <h6>
                      <Link
                        to="/work-detail"
                        className="text-dark fw-medium fs-15"
                      >
                        {title}
                      </Link>
                    </h6>
                    <p className="fs-14 text-muted font-weight-normal tag mb-0">
                      {tagName}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
      </div>
    </React.Fragment>
  );
};

// class Masonry extends React.Component {
//   constructor(props: any) {
//     super(props);
//     this.state = { columns: 1 };
//     // this.onResize = this.onResize.bind(this);
//   }

// componentDidMount() {
//     this.onResize();
//     window.addEventListener('resize', this.onResize)
// }

// componentWillUnmount() {
//     window.removeEventListener('resize', this.onResize)
// }

// getColumns(w : any) {
//     return this.props.brakePoints.reduceRight((p : any, c : any, i : any) => {
//         return c < w ? p : i;
//     }, this.props.brakePoints.length) + 1;
// }

// onResize() {
//     const columns = this.getColumns(this.refs.Masonry.offsetWidth);
//     if (columns !== this.state.columns) {
//         this.setState({ columns: columns });
//     }

// }

// mapChildren() {
//     let col = [];
//     const numC = this.state.columns;
//     for (let i = 0; i < numC; i++) {
//         col.push([]);
//     }
//     return this.props.children.reduce((p, c, i) => {
//         p[i % numC].push(c);
//         return p;
//     }, col);
// }

//   render() {
//     return (
//       <div className="masonry p-0" ref="Masonry">
//         {/* {this.mapChildren().map((col, ci) => {
//                   return (
//                       <div className="column" key={ci} >
//                           {col.map((child, i) => {
//                               return <div key={i} className="spacing picture-item">{child}</div>
//                           })}
//                       </div>
//                   )
//               })} */}
//       </div>
//     );
//   }
// }

export default WorkPortfolio;
