import React, { useState } from "react";
import {
  Col,
  Container,
  Nav,
  Row,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Detailspage from "./Detailspage";
import Description from "./Description";
import Reviews from "./Reviews";
import ReviewForm from "./ReviewForm";
import Clients from "../About/Clients";

const ProductDetails = () => {
  const [activeTab, setActiveTab] = useState<string>("1");

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <React.Fragment>
      <section>
        <Container>
          <Row>
            <Col lg={9}>
              <div className="page-title">
                <h2 className="text-dark fw-light lh-base mt-5">
                  360 Smart 720p CCTV MP{" "}
                  <span className="fw-medium title">Digital Camera</span>
                </h2>
                <p className="fs-16 text-muted mb-0">
                  Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                  consequat ipsum, nec sagittis sem nibh id elit. Proin gravida
                  nibh vel velit auctor Aenean sollicitudin, adipisicing elit
                  sed lorem quis bibendum auctor.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* import DetailsPage */}
      <Detailspage />

      <section className="section bg-light">
        <Container>
          <Row>
            <Col lg={12}>
              <nav className="pro-detail-area">
                <Nav tabs className="border-bottom detail-title">
                  <NavLink
                    className={classnames(
                      { active: activeTab === "1" },
                      "border-0"
                    )}
                    onClick={() => {
                      toggle("1");
                    }}
                    to="#"
                  >
                    Description
                  </NavLink>
                  <NavLink
                    className={classnames(
                      { active: activeTab === "2" },
                      "border-0"
                    )}
                    onClick={() => {
                      toggle("2");
                    }}
                    to="#"
                  >
                    Reviews
                  </NavLink>
                </Nav>
              </nav>
              <TabContent className="py-4" activeTab={activeTab}>
                <TabPane tabId="1">
                  <Description />
                </TabPane>
                <TabPane tabId="2">
                  <Reviews />
                </TabPane>
              </TabContent>
            </Col>

            {/* import Review Form */}
            <ReviewForm />
          </Row>
        </Container>
      </section>

      {/* import Client Section */}
      <Clients sectionTitle={true} />
    </React.Fragment>
  );
};

export default ProductDetails;
