import React from "react";
import { Link } from "react-router-dom";

const LeftSidebar = () => {
  return (
    <React.Fragment>
      <div>
        <div className="p-3 bg-light">
          <h6 className="mb-0">Categories</h6>
        </div>
        <div className="ms-3 my-3">
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckDefaulta1"
            />
            <label
              className="form-check-label text-dark ms-2"
              htmlFor="flexCheckDefaulta1"
            >
              Accessories
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckChecked11"
              defaultChecked
            />
            <label
              className="form-check-label text-dark ms-2"
              htmlFor="flexCheckChecked11"
            >
              Bag
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckChecked22"
            />
            <label
              className="form-check-label text-dark ms-2"
              htmlFor="flexCheckChecked22"
            >
              Clothing
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckChecked33"
            />
            <label
              className="form-check-label text-dark ms-2"
              htmlFor="flexCheckChecked33"
            >
              Decore
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckChecked44"
            />
            <label
              className="form-check-label text-dark ms-2"
              htmlFor="flexCheckChecked44"
            >
              Coffee Pots
            </label>
          </div>
          <Link className="fw-normal text-primary" to="#">
            & More Products
          </Link>
        </div>
      </div>

      <div className="mt-4">
        <div className="p-3 bg-light">
          <h6 className="mb-0">Choose Color</h6>
        </div>
        <div className="ms-3 my-3">
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckDefaulta2"
            />
            <label
              className="form-check-label text-dark"
              htmlFor="flexCheckDefaulta2"
            >
              <i className="mdi mdi-circle ms-2 fs-16"></i> Red
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckChecked55"
              defaultChecked
            />
            <label
              className="form-check-label text-dark"
              htmlFor="flexCheckChecked55"
            >
              <i className="mdi mdi-circle text-primary ms-2 fs-16"></i> Blue
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckChecked66"
            />
            <label
              className="form-check-label text-dark"
              htmlFor="flexCheckChecked66"
            >
              <i className="mdi mdi-circle text-dark ms-2 fs-16"></i> Black
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckChecked77"
            />
            <label
              className="form-check-label text-dark"
              htmlFor="flexCheckChecked77"
            >
              <i className="mdi mdi-circle text-info ms-2 fs-16"></i> Cyan
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckChecked88"
            />
            <label
              className="form-check-label text-dark"
              htmlFor="flexCheckChecked88"
            >
              <i className="mdi mdi-circle text-warning ms-2 fs-16"></i> Yellow
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckChecked99"
            />
            <label
              className="form-check-label text-dark"
              htmlFor="flexCheckChecked99"
            >
              <i className="mdi mdi-circle text-success ms-2 fs-16"></i> Green
            </label>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="p-3 bg-light">
          <h6 className="mb-0">Items</h6>
        </div>
        <div className="ms-3 my-3">
          <p className="d-inline-block text-dark fs-16">Select Items</p>
          <select
            className="form-select d-inline-block border w-50 ms-5 ms-md-2"
            id="autoSizingSelect"
          >
            <option defaultValue="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>
      </div>

      <div className="mt-4">
        <div className="p-3 bg-light mb-3">
          <h6 className="mb-0">Price</h6>
        </div>
        <div className="ms-3 my-3">
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckDefaulta7"
            />
            <label
              className="form-check-label text-dark ms-2"
              htmlFor="flexCheckDefaulta7"
            >
              $50 - $99
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckChecke"
              defaultChecked
            />
            <label
              className="form-check-label text-dark ms-2"
              htmlFor="flexCheckChecke"
            >
              $100 - $199
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckCheck"
            />
            <label
              className="form-check-label text-dark ms-2"
              htmlFor="flexCheckCheck"
            >
              $200 - $299
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckChec"
            />
            <label
              className="form-check-label text-dark ms-2"
              htmlFor="flexCheckChec"
            >
              $300 - $399
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              value=""
              id="flexCheckChe"
            />
            <label
              className="form-check-label text-dark ms-2"
              htmlFor="flexCheckChe"
            >
              $400 - $499
            </label>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LeftSidebar;
