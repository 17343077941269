import { Icon } from "@ailibs/feather-react-ts";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

//import images
import product1 from "../../assets/images/products/pro-1.jpg";
import product2 from "../../assets/images/products/pro-6.jpg";
import Clients from "../About/Clients";

const Cart = () => {
  return (
    <React.Fragment>
      <section>
        <Container>
          <Row>
            <Col lg={9}>
              <div className="page-title">
                <h2 className="text-dark fw-light lh-base mt-5">
                  All you need is in your{" "}
                  <span className="fw-medium title">cart</span> now.
                </h2>
                <p className="fs-16 text-muted mb-0">
                  Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                  consequat ipsum, nec sagittis sem nibh id elit. Proin gravida
                  nibh vel velit auctor Aenean sollicitudin, adipisicing elit
                  sed lorem quis bibendum auctor.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sm-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="table-responsive mt-4">
                <table className="table caption-top table-borderless pro-table">
                  <thead className="bg-light text-dark">
                    <tr>
                      <th scope="col" className="fw-semibold mt-2">
                        Products
                      </th>
                      <th scope="col" className="fw-semibold mt-2">
                        Price
                      </th>
                      <th scope="col" className="fw-semibold mt-2">
                        Discount
                      </th>
                      <th scope="col" className="fw-semibold mt-2">
                        Quantity
                      </th>
                      <th scope="col" className="fw-semibold mt-2">
                        Remove
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="align-middle">
                      <th scope="row">
                        <img
                          className="img-fluid rounded"
                          src={product1}
                          width="125"
                          alt=""
                        />
                      </th>
                      <td>
                        <h5 className="fs-16 fw-medium">Smart 720p CCTV</h5>
                        <div className="price d-inline-block">
                          <ins className="text-dark pe-1 fs-15 fw-semibold text-decoration-none">
                            $29.00
                          </ins>
                          <del className="text-muted fs-15">$30.00</del>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span className="badge bg-soft-primary text-primary rounded-3 fs-14 fw-bold py-2">
                            - 18%
                          </span>
                        </div>
                      </td>
                      <td>
                        <select
                          className="form-select border"
                          id="autoSizingSelect"
                        >
                          <option defaultValue="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select>
                      </td>
                      <td>
                        <Link to="#" className="text-dark fw-semibold">
                          Remove <span className="mx-3">|</span> Add To Wishlist
                        </Link>
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <th scope="row">
                        <img
                          className="img-fluid rounded"
                          src={product2}
                          width="125"
                          alt=""
                        />
                      </th>
                      <td>
                        <h5 className="fs-16 fw-medium">
                          Dell Laptop core pro
                        </h5>
                        <div className="price d-inline-block">
                          <ins className="text-dark pe-1 fs-15 fw-semibold text-decoration-none">
                            $49.00
                          </ins>
                          <del className="text-muted fs-15">$59.00</del>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span className="badge bg-soft-primary text-primary rounded-3 fs-14 fw-bold py-2">
                            - 20%
                          </span>
                        </div>
                      </td>
                      <td>
                        <select
                          className="form-select border"
                          id="autoSizingSelect1"
                        >
                          <option defaultValue="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select>
                      </td>
                      <td>
                        <Link to="#" className="text-dark fw-semibold">
                          Remove <span className="mx-3">|</span> Add To Wishlist
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Row className="border-top">
                <Col lg={6} className="mt-4">
                  <Row className="mb-3 g-2 align-items-center">
                    <label
                      htmlFor="inputCode"
                      className="col-sm-3 col-3 col-form-label text-uppercase text-dark fw-semibold"
                    >
                      <i className="mdi mdi-tag-multiple fs-18 text-dark align-middle"></i>{" "}
                      Apply Coupen
                    </label>
                    <Col sm={6} xs={5}>
                      <input
                        type="text"
                        className="form-control bg-light border"
                        id="inputCode"
                      />
                    </Col>
                    <Col sm={3} xs={4}>
                      <Link to="#" className="btn btn-secondary">
                        Apply
                        <Icon name="arrow-right" className="icon-xs" />
                      </Link>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4} className="offset-lg-2 mt-4">
                  <div>
                    <ul className="list-unstyled list-inline border-bottom py-3 mb-0">
                      <li className="list-inline-item">
                        <h5 className="fs-15 mb-0 fw-semibold">Price :</h5>
                      </li>
                      <li className="list-inline-item float-end text-dark">
                        $259.00
                      </li>
                    </ul>
                    <ul className="list-unstyled list-inline border-bottom py-3 mb-0">
                      <li className="list-inline-item">
                        <h5 className="fs-15 mb-0 fw-semibold">
                          Delivery Charge :
                        </h5>
                      </li>
                      <li className="list-inline-item float-end text-dark text-primary">
                        Free
                      </li>
                    </ul>
                    <ul className="list-unstyled list-inline border-bottom py-3 mb-0">
                      <li className="list-inline-item">
                        <h5 className="fs-15 mb-0 fw-semibold">Discount :</h5>
                      </li>
                      <li className="list-inline-item float-end text-dark">
                        $100.00
                      </li>
                    </ul>
                    <ul className="list-unstyled list-inline py-3 mb-0">
                      <li className="list-inline-item">
                        <h5 className="fs-16 mb-0 fw-semibold">Total :</h5>
                      </li>
                      <li className="list-inline-item fs-16 fw-semibold text-dark float-end">
                        $159.00
                      </li>
                    </ul>
                    <Link
                      to="/checkout"
                      className="btn btn-primary mt-3 float-end"
                    >
                      Proceed To Checkout
                      <Icon name="arrow-right" className="icon-xs icon" />
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* import Clients */}
      <Clients sectionTitle={true} />
    </React.Fragment>
  );
};

export default Cart;
