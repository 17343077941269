import { Icon } from "@ailibs/feather-react-ts";
import React from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

const Grid = ({ blogItem }: any) => {
  return (
    <React.Fragment>
      {(blogItem || []).map((item: any, key: number) => (
        <Col lg={6} key={key}>
          <article className="post position-relative pb-5">
            <div className="date-box rounded-3 shadow text-center bg-light position-absolute top-0 start-0 py-1 px-2 ms-3 mt-3">
              <h6 className="fs-14 fw-medium mb-0">{item.date}</h6>
            </div>
            <div className="post-preview mb-4">
              <Link to="/single-post">
                <img src={item.img} alt="" className="img-fluid rounded-3" />
              </Link>
            </div>
            <div className="post">
              <div className="d-flex align-items-center gap-3">
                <p className="fs-15 text-muted mb-0">
                  <Icon name="message-square" className="icon-xs me-1" />
                  <Link to="#" className="text-muted">
                    {item.message}
                  </Link>
                </p>
                <p className="fs-15 text-muted mb-0">
                  <Icon name="eye" className="icon-xs me-1" />
                  <Link to="#" className="text-muted">
                    {item.viewers}
                  </Link>
                </p>
              </div>
              <h6 className="fs-18 fw-semibold my-3 post-title">
                <Link to="/single-post">{item.title}</Link>
              </h6>
              <p className="text-muted">{item.desc}</p>
              <Link to="#" className="fw-medium text-dark">
                Read More <Icon name="arrow-right" className="icon-xs icon" />
              </Link>
            </div>
          </article>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default Grid;
