import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classname from "classnames";
import { Collapse, Container, NavbarToggler } from "reactstrap";

//import images
import logoLight from "../assets/images/logo-light.png";
import logoDark from "../assets/images/logo-dark.png";
import withRouter from "../Component/withRouter";

const Navbar = (props: any) => {
  const [isOpenMenu, setisOpenMenu] = useState<boolean>(false);
  const [product, setProduct] = useState<boolean>(false);
  const [portfolio, setPortfolio] = useState<boolean>(false);
  const [blog, setBlog] = useState<boolean>(false);
  const [navClass, setnavClass] = useState("");

  const toogle = () => {
    setisOpenMenu(!isOpenMenu);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass("nav-sticky");
    } else {
      setnavClass("");
    }
  }

  useEffect(() => {
    var matchingMenuItem = null;
    const ul: any = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    let itemsArray = [...items]; // converts NodeList to Array
    removeActivation(itemsArray);
    for (var i = 0; i < items.length; ++i) {
      if (props.router.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  function activateParentDropdown(item: any) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement.firstElementChild;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  const removeActivation = (items : any) => {
    let actiItems = items.filter((x : any) => x.classList.contains("active"));

    actiItems.forEach((item : any) => {
        if (item.classList.contains("menu-link")) {
            if (!item.classList.contains("active")) {
                item.setAttribute("aria-expanded", false);
            }
            if (item.nextElementSibling) {
                item.nextElementSibling.classList.remove("show");
            }
        }
        if (item.classList.contains("nav-link")) {
            if (item.nextElementSibling) {
                item.nextElementSibling.classList.remove("show");
            }
            item.setAttribute("aria-expanded", false);
        }
        item.classList.remove("active");
    });
};

  return (
    <React.Fragment>
      <nav
        className={
          "navbar navbar-expand-lg fixed-top sticky mx-auto " + navClass
        }
        id="navigation"
      >
        <Container>
          <Link to="/" className="navbar-brand me-4">
            <img src={logoLight} className="logo-light" alt="" height="18" />
            <img src={logoDark} className="logo-dark" alt="" height="18" />
          </Link>

          <div className="navbar-header">
            <NavbarToggler type="button" onClick={toogle}>
              <span className="mdi mdi-menu"></span>
            </NavbarToggler>
          </div>

          <Collapse
            className="navbar-collapse"
            id="navbar-collapse-1"
            isOpen={isOpenMenu}
          >
            <ul className="nav navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link sub-menu-item" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link sub-menu-item" to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link sub-menu-item" to="/services">
                  Services
                </Link>
              </li>
              <li className="nav-item dropdown parent-menu-item">
                <Link
                  className="nav-link menu-item dropdown-toggle"
                  to="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    setProduct(!product);
                  }}
                >
                  Shop
                </Link>
                <ul
                  className={classname("dropdown-menu border-0", {
                    show: product,
                  })}
                >
                  <li className="nav-item">
                    <Link className="nav-link sub-menu-item" to="/products">
                      Products
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link sub-menu-item" to="/product-list">
                      Product List
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link sub-menu-item"
                      to="/product-detail"
                    >
                      Product Details
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link sub-menu-item" to="/cart">
                      Cart
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link sub-menu-item" to="/checkout">
                      Checkout
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown parent-menu-item">
                <Link
                  className="nav-link menu-item dropdown-toggle"
                  to="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    setPortfolio(!portfolio);
                  }}
                >
                  Portfolio
                </Link>
                <ul
                  className={classname("dropdown-menu border-0", {
                    show: portfolio,
                  })}
                >
                  <li className="nav-item">
                    <Link className="nav-link sub-menu-item" to="/work">
                      Work
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link sub-menu-item" to="/work-detail">
                      Work Detail
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown parent-menu-item">
                <Link
                  className="nav-link menu-item dropdown-toggle"
                  to="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    setBlog(!blog);
                  }}
                >
                  Blog
                </Link>
                <ul
                  className={classname("dropdown-menu border-0", {
                    show: blog,
                  })}
                >
                  <li className="nav-item">
                    <Link className="nav-link sub-menu-item" to="/blog-listing">
                      Blog Listing
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link sub-menu-item" to="/blog-grid">
                      Blog Grid
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link sub-menu-item" to="/single-post">
                      Single Post
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link sub-menu-item" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </Collapse>
        </Container>
      </nav>
    </React.Fragment>
  );
};

export default withRouter(Navbar);
