import React from "react";
import { Col, Container, Row } from "reactstrap";
import Grid from "./Grid";
import { blogItem } from "./blogItem";
import RightSidebar from "../BlogList/RightSidebar";
import Pagination from "../Products/Pagination";
import Clients from "../About/Clients";

const BlogGrid = () => {
  return (
    <React.Fragment>
      <section>
        <Container>
          <Row>
            <Col lg={9}>
              <h2 className="text-dark fw-light lh-base mt-5">
                We are creative &
                <span className="fw-medium title"> Innovative</span>
              </h2>
              <p className="fs-16 text-muted mb-0">
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                consequat ipsum, nec sagittis sem nibh id elit. Proin gravida
                nibh vel velit auctor Aenean sollicitudin, adipisicing elit sed
                lorem quis bibendum auctor.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mt-4 pt-2">
        <Container>
          <Row>
            <Col lg={8}>
              <Row>
                <Grid blogItem={blogItem} />
              </Row>
              <Pagination />
            </Col>

            <RightSidebar />
          </Row>
        </Container>
      </section>

      {/* import Client */}
      <Clients sectionTitle={false} />
    </React.Fragment>
  );
};

export default BlogGrid;
