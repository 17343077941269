import React from "react";
import { Col, Container, Row } from "reactstrap";

//Import images
import bgImage from "../../assets/images/bg.jpg";
import user from "../../assets/images/user-1.png";
import team1 from "../../assets/images/team/team-1.jpg";
import team2 from "../../assets/images/team/team-2.jpg";
import team3 from "../../assets/images/team/team-3.jpg";
import team4 from "../../assets/images/team/team-4.jpg";

const Team = () => {
  return (
    <React.Fragment>
      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <img
                src={bgImage}
                alt="about-img"
                className="img-fluid img-thumbnail mt-5"
              />
            </Col>
          </Row>

          <Row className="text-center justify-content-center">
            <Col lg={8} className="mt-5">
              <h4 className="fw-semibold border-bottom d-inline-block text-dark pb-3">
                What is our clients say?
              </h4>
              <div className="testimonial-box">
                <i
                  className="icon-sm sw-1 my-3"
                  data-feather="message-square"
                ></i>
                <p className="text-muted fst-italic fs-18">
                  {" "}
                  "It is a long established fact that a reader will be
                  distracted by the readable content of a page when looking at
                  its layout. The point of using Lorem Ipsum is that it has a
                  more-or-less."
                </p>
                <img
                  src={user}
                  className="testi-user rounded-circle img-thumbnail"
                  alt="testimonials-user"
                  style={{ width: "64px", height: "64px" }}
                />
                <p className="text-muted fs-16 mt-3">
                  Template User - wozia Template
                </p>
              </div>
            </Col>
          </Row>

          <Row className="text-center">
            <Col lg={12} className="mt-5">
              <h4 className="fw-semibold border-bottom d-inline-block text-dark pb-3">
                Meet Our Team
              </h4>
            </Col>
          </Row>

          <div className="row text-center">
            <Col lg={3} className="mt-4">
              <div className="team-box">
                <img src={team1} className="img-fluid rounded" alt="" />
                <h5 className="text-dark mt-3 fs-16">Hubert Brandt</h5>
                <p className="text-muted">CEO/Founder</p>
              </div>
            </Col>
            <Col lg={3} className="mt-4">
              <div className="team-box">
                <img src={team2} className="img-fluid rounded" alt="" />
                <h5 className="text-dark mt-3 fs-16">Andrew Sparks</h5>
                <p className="text-muted">CTO/Co-Founder</p>
              </div>
            </Col>
            <Col lg={3} className="mt-4">
              <div className="team-box">
                <img src={team3} className="img-fluid rounded" alt="" />
                <h5 className="text-dark mt-3 fs-16">David Downs</h5>
                <p className="text-muted">Web Designer</p>
              </div>
            </Col>
            <Col lg={3} className="mt-4">
              <div className="team-box">
                <img src={team4} className="img-fluid rounded" alt="" />
                <h5 className="text-dark mt-3 fs-16">Jennifer Pak</h5>
                <p className="text-muted">Manager</p>
              </div>
            </Col>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Team;
