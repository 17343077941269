import React from "react";

const Description = () => {
  return (
    <React.Fragment>
      <p className="text-muted fs-16">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
        sagittis orci ac odio dictum tincidunt. Donec ut metus leo. Class aptent
        taciti sociosqu ad litora torquent per conubia nostra, per inceptos
        himenaeos. Sed luctus, dui eu sagittis sodales, nulla nibh sagittis
        augue, vel porttitor diam enim non metus. Vestibulum aliquam augue
        neque. Phasellus tincidunt odio eget ullamcorper efficitur. Cras
        placerat ut turpis pellentesque vulputate. Nam sed consequat tortor.
        Curabitur finibus sapien dolor. Ut eleifend tellus nec erat pulvinar
        dignissim. Nam non arcu purus. Vivamus et massa massa. <br />
        The full monty brilliant young delinquent burke naff baking cakes the
        wireless argy-bargy smashing, squiffy chimney pot I he nicked it twit
        brolly spend a penny he legged it, cup of tea is ruddy grub.!
      </p>

      <div className="row text-muted">
        <div className="col-md-6">
          <ul className="list-unstyled">
            <li className="py-1">
              <i className="mdi mdi-circle-medium text-primary"></i> Claritas
              est etiam processus dynamicus.
            </li>
            <li className="py-1">
              <i className="mdi mdi-circle-medium text-primary"></i> Mirum est
              notare quam littera.
            </li>
            <li className="py-1">
              <i className="mdi mdi-circle-medium text-primary"></i> Nam liber
              tempor cum soluta nobis eleifend.
            </li>
            <li className="py-1">
              <i className="mdi mdi-circle-medium text-primary"></i> Duis autem
              vel eum iriure dolor.
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="list-unstyled">
            <li className="py-1">
              <i className="mdi mdi-circle-medium text-primary"></i> Claritas
              est etiam processus dynamicus.
            </li>
            <li className="py-1">
              <i className="mdi mdi-circle-medium text-primary"></i> Mirum est
              notare quam littera.
            </li>
            <li className="py-1">
              <i className="mdi mdi-circle-medium text-primary"></i> Nam liber
              tempor cum soluta nobis eleifend.
            </li>
            <li className="py-1">
              <i className="mdi mdi-circle-medium text-primary"></i> Duis autem
              vel eum iriure dolor.
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Description;
