import React from "react";

const Section = () => {
  return (
    <React.Fragment>
      <section
        className="hero position-relative overflow-hidden align-items-center"
        id="home"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 text-center">
              <h2 className="text-dark fw-light lh-base mt-5">
                Wozia Is One Of Best{" "}
                <span className="fw-medium title">Web Design</span> &{" "}
                <span className="fw-medium title">Development</span> Company.
              </h2>

              <p className="text-muted fs-16">
                We create digital assets and we're focused on Web Technologies
                and Design, based on London, United Kingdom. We build creative &
                professional themes.
              </p>
              <h5 className="mt-4 fw-normal">
                {" "}
                A digital studio crafting{" "}
                <span className="title">beautiful</span> experiences.
              </h5>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Section;
