import { Icon } from "@ailibs/feather-react-ts";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const OrderDetails = () => {
  return (
    <React.Fragment>
      <Row>
        <Col lg={12} className="mt-4">
          <div className="bg-light rounded-3 p-4">
            <h5 className="fw-semibold border-bottom pb-3 fs-18 mb-3">
              {" "}
              Order Details
            </h5>
            <Row className="justify-content-between">
              <Col lg={2} md={3} className="my-2">
                <h5 className="fs-16 mb-2 fw-semibold">
                  <Icon name="arrow-right" className="icon-xs icon" /> Price
                </h5>
                <p className="text-muted mb-0 ms-3 ps-1 fs-15">$116.00</p>
              </Col>
              <Col lg={2} md={3} className="my-2">
                <h5 className="fs-16 mb-2 fw-semibold">
                  <Icon name="arrow-right" className="icon-xs icon" /> Discount
                </h5>
                <p className="text-muted mb-0 ms-3 ps-1 fs-15">$16.00</p>
              </Col>
              <Col lg={2} md={3} className="my-2">
                <h5 className="fs-16 mb-2 fw-semibold">
                  <Icon name="arrow-right" className="icon-xs icon" /> Delivery
                  Charge
                </h5>
                <p className="text-muted mb-0 ms-3 ps-1 fs-15">Free</p>
              </Col>
              <Col lg={2} md={3} className="my-2">
                <h5 className="fs-16 mb-2 fw-semibold">
                  <Icon name="arrow-right" className="icon-xs icon" /> Total
                </h5>
                <p className="text-muted mb-0 ms-3 ps-1 fs-15">$100.00</p>
              </Col>
              <Col lg={2} md={3} className="my-2">
                <Link to="#" className="btn btn-danger">
                  Pay Now <Icon name="arrow-right" className="icon-xs icon" />
                </Link>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default OrderDetails;
