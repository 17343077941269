import React from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";

//import images
import img1 from "../../assets/images/works/img4.jpg";
import img2 from "../../assets/images/works/img2.jpg";
import img3 from "../../assets/images/works/img10.jpg";

const RightSidebar = () => {
  return (
    <React.Fragment>
      <Col lg={4} md={6}>
        <div className="sidebar ms-3">
          <aside className="widget widget_search">
            <form className="position-relative">
              <input
                className="form-control"
                type="search"
                placeholder="Search..."
              />
              <button
                className="bg-transparent border-0 position-absolute top-50 end-0 translate-middle-y me-2"
                type="submit"
              >
                <span
                  className="icon-xs icon text-dark"
                  data-feather="search"
                ></span>
              </button>
            </form>
          </aside>
          <div>
            <div className="sd-title mt-4">
              <h6 className="border-bottom pb-3 mb-0">Categories</h6>
            </div>
            <div className="ms-3 my-3">
              <div className="form-check mb-2">
                <input
                  className="form-check-input fs-16"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label text-dark ms-2"
                  htmlFor="flexCheckDefault"
                >
                  Accessories
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  className="form-check-input fs-16"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked1"
                  defaultChecked
                />
                <label
                  className="form-check-label text-dark ms-2"
                  htmlFor="flexCheckChecked1"
                >
                  Bag
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  className="form-check-input fs-16"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked2"
                />
                <label
                  className="form-check-label text-dark ms-2"
                  htmlFor="flexCheckChecked2"
                >
                  Clothing
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  className="form-check-input fs-16"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked3"
                />
                <label
                  className="form-check-label text-dark ms-2"
                  htmlFor="flexCheckChecked3"
                >
                  Decore
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  className="form-check-input fs-16"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked4"
                />
                <label
                  className="form-check-label text-dark ms-2"
                  htmlFor="flexCheckChecked4"
                >
                  Coffee Pots
                </label>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="sd-title mt-4">
              <h6 className="border-bottom pb-3 mb-0">Polular Post</h6>
            </div>
            <ul className="list-unstyled my-4">
              <li className="d-flex mb-3 pb-3 border-bottom">
                <Link className="w-25 me-3" to="#">
                  <img src={img1} alt="" className="img-fluid rounded" />
                </Link>
                <div className="flex-1">
                  <Link to="#" className="text-dark">
                    Beautiful Day With Friends..
                  </Link>
                  <span className="d-block text-muted">Jun 15, 2021</span>
                </div>
              </li>
              <li className="d-flex mb-3 pb-3 border-bottom">
                <Link className="w-25 me-3" to="#">
                  <img src={img2} alt="" className="img-fluid rounded" />
                </Link>
                <div className="flex-1">
                  <Link to="#" className="text-dark">
                    Nature valley with cooling..
                  </Link>
                  <span className="d-block text-muted">Jun 10, 2021</span>
                </div>
              </li>
              <li className="d-flex mb-3">
                <Link className="w-25 me-3" to="#">
                  <img src={img3} alt="" className="img-fluid rounded" />
                </Link>
                <div className="flex-1">
                  <Link to="#" className="text-dark">
                    Beautiful Day With Friends..
                  </Link>
                  <span className="d-block text-muted">Jun 8, 2021</span>
                </div>
              </li>
            </ul>
          </div>

          <div className="mt-5">
            <div className="sd-title mt-4">
              <h6 className="border-bottom pb-3 mb-0">Text Widget</h6>
            </div>
            <p className="mx-3 my-3 text-muted">
              Exercitation photo booth stumptown tote bag Banksy, elit small
              batch freegan sed. Craft beer elit seitan exercitation, photo
              booth et 8-bit kale chips proident chillwave deep v laborum.
              Aliquip veniam delectus, Marfa eiusmod Pinterest in do umami
              readymade swag.
            </p>
          </div>

          <div className="mt-5">
            <div className="sd-title mt-4">
              <h6 className="border-bottom pb-3 mb-0">Archives</h6>
            </div>
            <ul className="list-unstyled my-4">
              <li className="py-1 text-dark">
                <Link className="me-2 text-muted" to="#">
                  March 2021
                </Link>{" "}
                (40)
              </li>
              <li className="py-1 text-dark">
                <Link className="me-2 text-muted" to="#">
                  April 2021
                </Link>{" "}
                (08)
              </li>
              <li className="py-1 text-dark">
                <Link className="me-2 text-muted" to="#">
                  May 2021
                </Link>{" "}
                (11)
              </li>
              <li className="py-1 text-dark">
                <Link className="me-2 text-muted" to="#">
                  Jun 2021
                </Link>{" "}
                (21)
              </li>
            </ul>
          </div>

          <div className="mt-5">
            <div className="sd-title mt-4">
              <h6 className="border-bottom pb-3 mb-0">Tags</h6>
            </div>
            <div className="tagcloud py-4">
              <Link
                className="rounded d-inline-block fs-13 fw-medium mx-1 my-1 px-2 py-1"
                to="#"
              >
                logo
              </Link>
              <Link
                className="rounded d-inline-block fs-13 fw-medium mx-1 my-1 px-2 py-1"
                to="#"
              >
                business
              </Link>
              <Link
                className="rounded d-inline-block fs-13 fw-medium mx-1 my-1 px-2 py-1"
                to="#"
              >
                corporate
              </Link>
              <Link
                className="rounded d-inline-block fs-13 fw-medium mx-1 my-1 px-2 py-1"
                to="#"
              >
                e-commerce
              </Link>
              <Link
                className="rounded d-inline-block fs-13 fw-medium mx-1 my-1 px-2 py-1"
                to="#"
              >
                agency
              </Link>
              <Link
                className="rounded d-inline-block fs-13 fw-medium mx-1 my-1 px-2 py-1"
                to="#"
              >
                responsive
              </Link>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default RightSidebar;
