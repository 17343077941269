import React from "react";
import Clients from "./Clients";
import Section from "./Section";
import Team from "./Team";

const About = () => {
  return (
    <React.Fragment>
      {/* import Section */}
      <Section />

      {/* import Team Box */}
      <Team />

      {/* import Client */}
      <Clients sectionTitle={true} />
    </React.Fragment>
  );
};

export default About;
