import React from "react";
import { Col, Container, Row } from "reactstrap";
import Clients from "../About/Clients";
import WorkDetailsPage from "./WorkDetailsPage";

const PortfolioWorkDetails = () => {
  return (
    <React.Fragment>
      <section>
        <Container>
          <Row>
            <Col lg={9}>
              <div className="page-title">
                <h2 className="text-dark fw-light lh-base mt-5">
                  Any variation that fits your{" "}
                  <span className="fw-medium title">variation</span>
                </h2>
                <p className="fs-16 text-muted mb-0">
                  Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                  consequat ipsum, nec sagittis sem nibh id elit. Proin gravida
                  nibh vel velit auctor Aenean sollicitudin, adipisicing elit
                  sed lorem quis bibendum auctor.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* import WorkDetailsPage */}
      <WorkDetailsPage />

      {/* import Client Section */}
      <Clients sectionTitle={true} />
    </React.Fragment>
  );
};

export default PortfolioWorkDetails;
