import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

//import images
import product1 from "../../assets/images/products/pro-1.jpg";
import product2 from "../../assets/images/products/pro-3.jpg";

const OrderSummary = () => {
  return (
    <React.Fragment>
      <div className="mt-5">
        <h5 className="fw-semibold fs-18">Order Summary</h5>
        <Row className="align-items-center">
          <Col md={3} className="mt-4">
            <img className="img-fluid rounded-3" src={product1} alt="" />
          </Col>
          <Col md={4} className="mt-4">
            <h5 className="fs-16 fw-semibold">Smart 720p</h5>
            <div className="price d-inline-block">
              <ins className="pe-1 fs-15 fw-semibold text-primary text-decoration-none">
                $29.00
              </ins>
              <del className="text-muted fs-15">$30.00</del>
            </div>
            <select
              className="form-select border w-50 mt-3"
              id="autoSizingSelect3"
            >
              <option defaultValue="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </Col>
          <Col md={5} className="mt-sm-0 mt-4">
            <Link to="#" className="text-uppercase text-muted fw-semibold">
              Remove <span className="mx-3">|</span> Add To Wishlist
            </Link>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col md={3} className="mt-4">
            <img className="img-fluid rounded-3" src={product2} alt="" />
          </Col>
          <Col md={4} className="mt-4">
            <h5 className="fs-16 fw-semibold">Lenovo - ceneral due core</h5>
            <div className="price d-inline-block">
              <ins className="pe-1 fs-15 fw-semibold text-primary text-decoration-none">
                $29.00
              </ins>
              <del className="text-muted fs-15">$30.00</del>
            </div>
            <select
              className="form-select border w-50 mt-3"
              id="autoSizingSelect4"
            >
              <option defaultValue="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </Col>
          <Col md={5} className="mt-sm-0 mt-4">
            <Link to="#" className="text-uppercase text-muted fw-semibold">
              Remove <span className="mx-3">|</span> Add To Wishlist
            </Link>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default OrderSummary;
