import React from "react";
import Routes from "./Routes/index";


//import Icons
import "./assets/css/pe-icon-7-stroke.css";

import "./assets/css/materialdesignicons.min.css";

function App() {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
