import React from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

const MenuItem = ({ products }: any) => {
  return (
    <React.Fragment>
      {(products || []).map((product: any, key: number) => (
        <Col lg={4} md={6} key={key}>
          <div className="product-item mt-4">
            <div className="product-img rounded position-relative">
              <img className="img-fluid" src={product.img} alt="" />
              <span className="text-dark badge bg-light rounded-3 fs-14 fw-bold position-absolute top-0 start-0 mt-2 ms-2 py-2">
                {product.discount}
              </span>

              <div className="hover-content text-center w-100 position-absolute bottom-0 start-50 translate-middle-x py-2 mb-3">
                <Link to="/cart">
                  <i className="mdi mdi-cart-outline fs-22 me-3 pe-3 fw-bolder pro-icon border-end"></i>
                </Link>
                <Link to="/product-detail">
                  <i className="mdi mdi-eye-outline fs-22 me-3 pe-3 fw-bolder pro-icon border-end active"></i>
                </Link>
                <Link to="/cart">
                  <i className="mdi mdi-heart-outline fs-22 fw-bolder pro-icon"></i>
                </Link>
              </div>
            </div>
            <div className="pro-content my-3">
              <div className="d-flex align-items-center">
                <ins className="pe-2 fs-15 fw-semibold text-decoration-none text-dark">
                  {product.price}
                </ins>
                <del className="text-muted fs-15">{product.discountPrice}</del>
                <div className="rating ms-auto">
                  <i className="mdi mdi-star fs-18 text-warning d-inline-block"></i>
                  <h5 className="d-inline-block fs-15">{product.rating}</h5>
                </div>
              </div>
              <Link to="/product-detail">
                <p className="text-muted fw-normal lh-base my-1">
                  {product.desc}
                </p>
              </Link>
            </div>
          </div>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default MenuItem;
