import React from "react";
import { Col, Container, Row } from "reactstrap";
import Clients from "../About/Clients";
import ServicesBox from "./ServicesBox";

const Services = () => {
  const services = [
    {
      id: 1,
      icon: "pe-7s-leaf",
      title: "Branding",
      desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.",
    },
    {
      id: 2,
      icon: "pe-7s-helm",
      title: "Highly customizable",
      desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.",
    },
    {
      id: 3,
      icon: "pe-7s-airplay",
      title: "Responsive design",
      desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.",
    },
    {
      id: 4,
      icon: "pe-7s-coffee",
      title: "User Experience",
      desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.",
    },
    {
      id: 5,
      icon: "pe-7s-rocket",
      title: "Optimised for speed",
      desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.",
    },
    {
      id: 6,
      icon: "pe-7s-headphones",
      title: "Dedicated support",
      desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.",
    },
  ];

  return (
    <React.Fragment>
      <section>
        <Container>
          <Row>
            <Col lg={9}>
              <h2 className="text-dark fw-light lh-base mt-5">
                We provide a absolute list of{" "}
                <span className="fw-medium title">Superior</span> digital
                services.
              </h2>
              <p className="fs-16 text-muted mb-0">
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                consequat ipsum, nec sagittis sem nibh id elit. Proin gravida
                nibh vel velit auctor Aenean sollicitudin, adipisicing elit sed
                lorem quis bibendum auctor.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mt-5">
        <div className="container">
          <div className="row">
            {services.map((service, key) => (
              <div className="col-lg-4" key={key}>
                <ServicesBox service={service} />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* import Clients */}
      <Clients sectionTitle={true} />
    </React.Fragment>
  );
};

export default Services;
