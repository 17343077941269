import About from "../pages/About";
import Home from "../pages/Home/index";
import Services from "../pages/Services";
import Products from "../pages/Products";
import ProductList from "../pages/ProductList";
import ProductDetails from "../pages/ProductDetails";
import Cart from "../pages/Cart";
import Checkout from "../pages/Checkout";
import PortfolioWork from "../pages/PortfolioWork";
import PortfolioWorkDetails from "../pages/PortfolioWorkDetails";
import BlogList from "../pages/BlogList";
import BlogGrid from "../pages/BlogGrid";
import SinglePost from "../pages/SinglePost";
import Contact from "../pages/Contact";

interface RouteProps {
  path: string;
  component?: any;
  exact?: boolean;
}

const Routes: Array<RouteProps> = [
  //Contact
  { path: "/contact", component: <Contact /> },

  //Blog
  { path: "/single-post", component: <SinglePost /> },
  { path: "/blog-grid", component: <BlogGrid /> },
  { path: "/blog-listing", component: <BlogList /> },

  //PortFolio
  { path: "/work-detail", component: <PortfolioWorkDetails /> },
  { path: "/work", component: <PortfolioWork /> },

  //Product
  { path: "/checkout", component: <Checkout /> },
  { path: "/cart", component: <Cart /> },
  { path: "/product-detail", component: <ProductDetails /> },
  { path: "/product-list", component: <ProductList /> },
  { path: "/products", component: <Products /> },

  { path: "/services", component: <Services /> },
  { path: "/about", component: <About /> },
  { path: "/", component: <Home /> },
];

export default Routes;
